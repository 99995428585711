import axiosApiInstance from '@/config/axios.config'

import { ProductResponseModel } from '@/models/product.model'

export default class ProductClient {
    static async getProducts (data) {
        const response = await axiosApiInstance.get('/v1/shop/products', { params: data })
        return new ProductResponseModel(response.data)
    }
}
