<template lang="pug">
  .left-aside
    .left-aside__categories(v-if="!isMobile && categories.length")
      h2.left-aside__title {{ $t('categories') }}
      ul.left-aside__list(v-if="categories.length")
        li.left-aside__item(
          v-for="category in categories"
          :key="category.id"
        )
          router-link(
            :to=`$i18nRoute({
                name: 'ProductsCategory',
                params: {
                    category: category.name.toLowerCase(),
                    category_id: category.id
                }
            })`
          ).left-aside__item-title {{ category.name | toCapitalize }}
          ul.left-aside__list
            li.left-aside__item(
              v-for="manufacturer in category.manufacturers"
            )
              router-link(
                :to=`$i18nRoute({
                     name: 'ProductsCategoryManufacture',
                     params: {
                         category: category.name.toLowerCase(),
                         manufacture: productName(manufacturer.name),
                         category_id: category.id,
                         manufacturer_id: manufacturer.id,
                     }
                })`
              ) {{ manufacturer.name | toCapitalize }}

      p(v-else) Loading...
    ExpandableItem(v-else).left-aside__expandable
      template(v-slot:toggle)
        h3.expandable__title.left-aside__title
          span {{ $t('categories') }}
          BaseIcon(name="chevron" class="expandable__chevron")
      template(v-slot:expandable)
        ul.left-aside__list
          li.left-aside__item(
            v-for="category_mobile in categories"
            :key="category_mobile.id"
          )
            router-link(
              :to=`$i18nRoute({
                name: 'ProductsCategory',
                params: {
                    category: category_mobile.name.toLowerCase(),
                    category_id: category_mobile.id
                }
            })`
            ).left-aside__item-title {{ category_mobile.name | toCapitalize }}
            ul.left-aside__list
              li.left-aside__item(
                v-for="manufacturer in category_mobile.manufacturers"
              )
                router-link(
                  :to=`$i18nRoute({
                     name: 'ProductsCategoryManufacture',
                     params: {
                         category: category_mobile.name.toLowerCase(),
                         manufacture: productName(manufacturer.name),
                         category_id: category_mobile.id,
                         manufacturer_id: manufacturer.id,
                     }
                })`
                ) {{ manufacturer.name | toCapitalize }}
</template>

<script>
import ExpandableItem from '@/components/common/ExpandableItem'
import BaseIcon from '@/components/common/BaseIcon'
import { mapState } from 'vuex'

export default {
  name: 'LeftAside',
  components: { ExpandableItem, BaseIcon },
  computed: {
    ...mapState({
      isMobile: state => state.common.mediumScreen,
      categories: state => state.categories.categories
    })
  },
  methods: {
    toCapitalize (value) {
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    productName (value) {
      return value.replace(/ /g, '-').toLowerCase()
    }
  }
}
</script>
