class ProductRequestModel {
    product_id
    category_id
    manufacturer_id
    offset
    limit
    lang

    constructor (data) {
        this.product_id = data.product_id
        this.category_id = data.category_id
        this.manufacturer_id = data.manufacturer_id
        this.offset = data.offset
        this.limit = data.limit
        this.lang = data.lang
    }
}

class ProductResponseModel {
    count
    products

    constructor (data) {
        this.count = data.count
        this.products = data.products
    }
}

export {
    ProductRequestModel,
    ProductResponseModel
}
