var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.product)?_c('div',{staticClass:"product"},[_c('Breadcrumb',{attrs:{"current_path":{name: 'Product', title: _vm.product.name, params: {..._vm.$route.params}},"previous_path":[
      {
          name: 'ProductsCategory',
          title: _vm.$route.params.category,
          params: {
              category: _vm.$route.params.category,
              manufacture: _vm.$route.params.manufacture
          },
      },
      {
          name: 'ProductsCategoryManufacture',
          title: _vm.$route.params.manufacture,
          params: {
              category: _vm.$route.params.category,
              manufacture: _vm.$route.params.manufacture
          }
      }
    ]}}),_c('div',{staticClass:"container product__container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-3"},[_c('LeftAside')],1),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-9"},[_c('div',{staticClass:"product__content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-4"},[_c('div',{staticClass:"product__image",on:{"click":_vm.showImage}},[_c('BaseImage',{attrs:{"path":_vm.product.img,"external":""}})],1)]),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-8"},[_c('h1',{staticClass:"product__title"},[_vm._v(_vm._s(_vm.product.name))]),_c('h2',{staticClass:"product__subtitle"},[_vm._v(_vm._s(_vm.product.full_name))]),_c('p',{staticClass:"product__volume"},[_vm._v(_vm._s(_vm.product.volume))]),_c('p',{staticClass:"product__text"},[_vm._v(_vm._s(_vm.product.description))]),_c('p',{staticClass:"product__article"},[_vm._v(_vm._s(_vm.$t('pages.products.article_number'))+": "+_vm._s(_vm.product.article_number))]),_c('p',{staticClass:"product__categories"},[_vm._v(_vm._s(_vm.$t('categories'))+": "+_vm._s(_vm.product.manufacturer.name))])])])])])])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.previewImage)?_c('div',{staticClass:"popup"},[_c('div',{staticClass:"popup__preview"},[_c('div',{staticClass:"popup__preview-title"},[_c('button',{staticClass:"popup__close-button",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.closePreview()}}})]),_c('div',{staticClass:"popup__preview-content"},[_c('BaseImage',{attrs:{"path":_vm.product.img,"external":""}})],1)])]):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }