var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-aside"},[(!_vm.isMobile && _vm.categories.length)?_c('div',{staticClass:"left-aside__categories"},[_c('h2',{staticClass:"left-aside__title"},[_vm._v(_vm._s(_vm.$t('categories')))]),(_vm.categories.length)?_c('ul',{staticClass:"left-aside__list"},_vm._l((_vm.categories),function(category){return _c('li',{key:category.id,staticClass:"left-aside__item"},[_c('router-link',{staticClass:"left-aside__item-title",attrs:{"to":_vm.$i18nRoute({
              name: 'ProductsCategory',
              params: {
                  category: category.name.toLowerCase(),
                  category_id: category.id
              }
          })}},[_vm._v(_vm._s(_vm._f("toCapitalize")(category.name)))]),_c('ul',{staticClass:"left-aside__list"},_vm._l((category.manufacturers),function(manufacturer){return _c('li',{staticClass:"left-aside__item"},[_c('router-link',{attrs:{"to":_vm.$i18nRoute({
                   name: 'ProductsCategoryManufacture',
                   params: {
                       category: category.name.toLowerCase(),
                       manufacture: _vm.productName(manufacturer.name),
                       category_id: category.id,
                       manufacturer_id: manufacturer.id,
                   }
              })}},[_vm._v(_vm._s(_vm._f("toCapitalize")(manufacturer.name)))])],1)}),0)],1)}),0):_c('p',[_vm._v("Loading...")])]):_c('ExpandableItem',{staticClass:"left-aside__expandable",scopedSlots:_vm._u([{key:"toggle",fn:function(){return [_c('h3',{staticClass:"expandable__title left-aside__title"},[_c('span',[_vm._v(_vm._s(_vm.$t('categories')))]),_c('BaseIcon',{staticClass:"expandable__chevron",attrs:{"name":"chevron"}})],1)]},proxy:true},{key:"expandable",fn:function(){return [_c('ul',{staticClass:"left-aside__list"},_vm._l((_vm.categories),function(category_mobile){return _c('li',{key:category_mobile.id,staticClass:"left-aside__item"},[_c('router-link',{staticClass:"left-aside__item-title",attrs:{"to":_vm.$i18nRoute({
              name: 'ProductsCategory',
              params: {
                  category: category_mobile.name.toLowerCase(),
                  category_id: category_mobile.id
              }
          })}},[_vm._v(_vm._s(_vm._f("toCapitalize")(category_mobile.name)))]),_c('ul',{staticClass:"left-aside__list"},_vm._l((category_mobile.manufacturers),function(manufacturer){return _c('li',{staticClass:"left-aside__item"},[_c('router-link',{attrs:{"to":_vm.$i18nRoute({
                   name: 'ProductsCategoryManufacture',
                   params: {
                       category: category_mobile.name.toLowerCase(),
                       manufacture: _vm.productName(manufacturer.name),
                       category_id: category_mobile.id,
                       manufacturer_id: manufacturer.id,
                   }
              })}},[_vm._v(_vm._s(_vm._f("toCapitalize")(manufacturer.name)))])],1)}),0)],1)}),0)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }