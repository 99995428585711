<template lang="pug">
  .product(v-if="product")
    Breadcrumb(
      :current_path="{name: 'Product', title: product.name, params: {...$route.params}}"
      :previous_path=`[
        {
            name: 'ProductsCategory',
            title: $route.params.category,
            params: {
                category: $route.params.category,
                manufacture: $route.params.manufacture
            },
        },
        {
            name: 'ProductsCategoryManufacture',
            title: $route.params.manufacture,
            params: {
                category: $route.params.category,
                manufacture: $route.params.manufacture
            }
        }
      ]`
    )

    .container.product__container
      .row
        .col-xs-12.col-sm-12.col-md-3
          LeftAside
        .col-xs-12.col-sm-12.col-md-9
          .product__content
            .row
              .col-xs-12.col-sm-12.col-md-4
                .product__image(@click="showImage")
                  BaseImage(:path="product.img" external)

              .col-xs-12.col-sm-12.col-md-8
                h1.product__title {{ product.name }}
                h2.product__subtitle {{ product.full_name }}
                p.product__volume {{ product.volume }}
                p.product__text {{ product.description }}
                p.product__article {{ $t('pages.products.article_number') }}: {{ product.article_number }}
                p.product__categories {{ $t('categories') }}: {{ product.manufacturer.name }}

    transition(name="fade")
      .popup(v-if="previewImage")
        .popup__preview
          .popup__preview-title
            button(type="button" @click.prevent="closePreview()").popup__close-button
          .popup__preview-content
            BaseImage(:path="product.img" external)
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb'
import LeftAside from '@/components/LeftAside'
import BaseImage from '@/components/common/BaseImage'
import ProductService from '@/services/product.service'

export default {
  name: 'Product',
  components: { BaseImage, LeftAside, Breadcrumb },
  data: () => ({
    product: null,
    previewImage: false
  }),
  methods: {
    async getProducts (product_id) {
      await ProductService.getProducts({ product_id })
          .then(response => this.product = response.products[0])
    },
    showImage () {
      this.previewImage = true
    },
    closePreview () {
      this.previewImage = false
    }
  },
  mounted () {
    this.getProducts(this.$route.params.id)
  }
}
</script>
